import { getMadeForFontUrl } from '@wix/santa-editor-utils/src/fonts/madeFor';

export const loadMadeforFont = async () => {
  const madeforLink = window.document.createElement('link');

  madeforLink.href = getMadeForFontUrl(window.serviceTopology.scriptsDomainUrl);
  madeforLink.rel = 'stylesheet';
  madeforLink.type = 'text/css';

  const fontsPromise = new Promise((resolve) => {
    madeforLink.onload = resolve;
  });

  window.document.head.append(madeforLink);
  window.document.body.setAttribute('data-madefor', 'true');

  return fontsPromise;
};
