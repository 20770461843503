export function pickByValuePredicate<T>(
  sourceObject: { [s: string]: T },
  predicate: (value: T, key: string) => boolean,
) {
  return Object.fromEntries(
    Object.entries(sourceObject).filter(([key, value]) =>
      predicate(value, key),
    ),
  );
}
