export const parseHtmlEntities = (str: string): string => {
  return str.replace(/&#([0-9]{1,5});/gi, (match, numStr) => {
    const charCode = parseInt(numStr, 10);
    return String.fromCharCode(charCode);
  });
};

export const htmlDecode = (text: string): string => {
  const element = document.createElement('textarea');
  element.innerHTML = text;
  return element.childNodes.length === 0 ? '' : element.childNodes[0].nodeValue;
};

export const getParamFromUrl = (paramName: string): string => {
  const getParameterByName = window.queryUtil?.getParameterByName;
  if (!getParameterByName) {
    return '';
  }

  const paramValue = window.decodeURIComponent(getParameterByName(paramName));
  if (paramValue === 'undefined') {
    return '';
  }
  const parsedText = parseHtmlEntities(paramValue);
  return htmlDecode(parsedText);
};
