import { editorBrowserWindowClose } from '@wix/bi-logger-editor/v2';

import type { Versions } from '../types';
import type { ServiceTopology } from 'types/core';
import type { EditorLoggers } from '../editorLoggers';
import type { WixBaseUiEnvironmentProviderProps } from '@wix/wix-base-ui/src/util/WixBaseUiEnvironment/WixBaseUiEnvironment';

function unpkg(pkg: string, v: string, p: string) {
  return 'https://static.parastorage.com/unpkg/'
    .concat(pkg, '@')
    .concat(v, '/')
    .concat(p); // return `//unpkg.parastorage.com/${pkg}@${v}/${p}`
}

function unpkgObj(pkg: string, v: string, min: string, debug: string) {
  return {
    min: unpkg(pkg, v, min),
    source: unpkg(pkg, v, debug),
  };
}

function node(m: string, p: string) {
  return 'node_modules/'.concat(m, '/').concat(p);
}

function nodeObj(m: string, min: string, debug: string) {
  return {
    min: node(m, min),
    source: node(m, debug),
  };
}

export function scriptLocation(
  serviceTopology: ServiceTopology,
  name: string,
  fallback?: string,
) {
  return (
    (serviceTopology && serviceTopology.scriptsLocationMap[name]) || fallback
  );
}

export function unpkgOrNode(
  versions: Versions,
  local: boolean,
  m: string,
  p: string,
) {
  const actualLocal = isLocal(versions, local, m);
  return actualLocal ? node(m, p) : unpkg(m, versions[m], p);
}

export function isLocal(versions: Versions, local: Boolean, m: string) {
  return local || versions[m] === 'link';
}

export function unpkgOrNodeObj(
  versions: Versions,
  local: boolean,
  m: string,
  min: string,
  debug: string,
) {
  const actualLocal = isLocal(versions, local, m);
  return actualLocal
    ? nodeObj(m, min, debug)
    : unpkgObj(m, versions[m], min, debug);
}

export const VERSION_REGEXP = /\/(\d+\.\d+\.\d+)\/?$/;

export function getBaseVersion(url: string) {
  const semverMatches = url.match(VERSION_REGEXP);
  return semverMatches && semverMatches[1];
}

export function registerToTabLeaveEvent({
  editorLoggers,
}: {
  editorLoggers: EditorLoggers;
}) {
  const onLeave = () => {
    editorLoggers.bi.logger.report(editorBrowserWindowClose({}));
    flush();
  };

  // in case if batching enabled, flush the batch
  const flush = () => {
    editorLoggers.bi.logger.flush();
    editorLoggers.fedops.logger.flush();
  };

  window.addEventListener('beforeunload', function () {
    flush();
  });

  window.addEventListener('pagehide', () => {
    flush();
  });

  window.addEventListener('unload', function () {
    onLeave();
  });
}

export const getBaseUIOverrides = (
  overrides: string,
): Partial<WixBaseUiEnvironmentProviderProps> => {
  if (!overrides) return {};
  return overrides.split(',').reduce((acc, propAndValue) => {
    const propAndValueEntry = propAndValue.split(':');
    const [prop] = propAndValueEntry;
    let value: boolean | string = propAndValueEntry[1];
    if (!prop || !value) return acc;
    if (value === 'true') value = true;
    if (value === 'false') value = false;
    return {
      ...acc,
      [prop]: value,
    };
  }, {});
};
